<template>
<transition name="fade-bottom" mode="out-in">
    <div v-if="isVisible" class="card mb-3">
        <div class="card-body p-lg-3">
            <div class="card-widgets">
                <a href="javascript: void(0);" @click="refreshContent">
                    <i class="mdi mdi-refresh"></i>
                </a>
                <a :class="[
                showCollapse ? 'collapsed' : null,
                showCollapse ? 'mdi mdi-minus' : 'mdi mdi-plus'
                ]" :aria-expanded="showCollapse ? 'true' : 'false'" aria-controls="collapse-1" @click="collapse"></a>
                <span @click.prevent="isVisible = !isVisible" class="cursor-pointer">
                    <i class="mdi mdi-close"></i>
                </span>
            </div>
            <h5 class="header-title mb-0" :class="portletclass">{{ headertitle }}</h5>
        </div>
        <!-- End card header -->

        <b-collapse id="collapse-1" v-model="showCollapse">
            <slot></slot>
        </b-collapse>

        <div v-if="isLoading" class="card-disabled">
            <div class="card-portlets-loader">
                <div class="spinner-border text-primary m-2" role="status"></div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    props: {
        headertitle: {
            type: String,
            default: ""
        },
        portletclass: {
            type: String,
            default: ""
        },
        open:{
            type: Boolean,
            default: false
        },
        visible:{
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isVisible: this.visible,
            showCollapse: false,
            isLoading: false
        };
    },
    watch: {
        visible (val) {
            this.isVisible = !this.isVisible
        }
    },
    methods: {
        refreshContent() {
            this.isLoading = true;
            setTimeout(() => {
                this.isLoading = false
            },400)
        },
        remove() {
            this.isVisible = false;
        },
        collapse() {
            this.showCollapse = !this.showCollapse;
        }
    },
    mounted(){
        if(this.open) {this.showCollapse = true}
    }
};
</script>


<style lang="scss">

.card-widgets {
    float: right;
    height: 16px;
    > a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &:before {
                    content: "\F0415";
                }
            }
        }
    }
}

.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: .45rem;
    background: rgba(var(--bs-light-rgb),0.4);
    z-index: 2;
    cursor: progress;

    .card-portlets-loader {
        
        position: absolute;
        left: calc(50% -  1rem);
        top: calc(50% -  1rem);
        margin-left: - (1.5rem / 2);
        margin-top: - (1.5rem / 2);
    }
}
</style>